/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Sin querer entrar en la metafísica discusión de qué es el arte, sí voy a pasar por la conversación ", React.createElement(_components.a, {
    href: "http://voylinux.com/el-noble-arte-de-hacer-fotos-de-cosas-con-el-movil/"
  }, "que Pablo Bernardo empezó"), ", y que ha tenido ", React.createElement(_components.a, {
    href: "https://exetia.wordpress.com/2015/09/08/pulpo-no-vale-como-animal-de-compania/"
  }, "su buena crítica"), ", para dejar por escrito las ideas que llevo años sosteniendo en discusiones similares."), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Arte no es tecnología: La tecnología permite nuevos tipos de arte, pero en sí no hace al artista, aunque ya se ha hablado del origen que la palabra arte tiene en la de técnica, no es el significado que nos ha llegado a la actualidad. También se ha nombrado ya el hecho de que existen obras de arte que se pueden haber creado con un rodillo y un bote de Titanlux y no por eso dejan de considerarse arte."), "\n", React.createElement(_components.li, null, "Arte no es esfuerzo: Al punto anterior me remito, en el caso de Malévich se podría argumentar: “¿Y el esfuerzo mental?” A lo que sólo me queda responder:[embed width=“580”]https://www.youtube.com/watch?v=MDOnBv0gCKg[/embed]"), "\n", React.createElement(_components.li, null, "Arte no es buen gusto: Algo que se suele confundir con demasiada frecuencia. Buen gusto es lo que socialmente se reconoce como aceptable, es decir por la mayoría, pero eso no quiere decir que porque esté hecho con “buen gusto” sea arte, hay obras de Schönberg que siguen sin considerarse de buen gusto y ningún estudioso negaría de que son claramente artísticas.[embed width=“580”]https://www.youtube.com/watch?v=3RqfXRBt6XI[/embed]"), "\n", React.createElement(_components.li, null, "Arte no es elitismo: Sigo con la música porque para mí es el ejemplo más claro de que como el arte puede verse asaltado por el elitismo, desde el barroco donde un tal J. S. Bach ", React.createElement(_components.a, {
    href: "https://es.wikipedia.org/wiki/El_clave_bien_temperado"
  }, "dejó escrito"), " cuál debía ser el “buen gusto” durante los siguientes 200 años se empezó a gestar el conservatorio de música, fuera del que no existía el arte, pero a partir del sigo XX la música popular abrió una nueva rama que cambiaría para siempre la historia de la música, el jazz. Hoy en día no considerar el jazz como arte no cabe en casi ningún sitio, hasta los conservatorios lo han absorbido en parte y si consideramos el jazz arte, ¿por qué no todos sus hijos, nietos y otros descendientes? Blues, rock, música electrónica… Lo que me lleva al siguiente punto."), "\n", React.createElement(_components.li, null, "Arte no es entretenimiento: Hay un punto en que se diluye el arte para convertirse en entretenimiento, puede parecer una frontera poco clara, incluso podemos ponernos cínicos y negarla, pero dos cosas que distinguen el entretenimiento del arte.", "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "El entretenimiento es un producto: En el sentido de que su fin es ofrecerse a un consumidor que de alguna forma paga por él."), "\n", React.createElement(_components.li, null, "Tiene una vida útil limitada: se “desgasta” con el consumo, las obras de arte, por mucho que las veas, escuches o sientas no dejan de provocar algún sentimiento."), "\n"), "\n"), "\n"), "\n", React.createElement(_components.p, null, "Y hasta aquí mis ideas al respecto, que por supuesto estoy abierto a debatir."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
